// SecondPage.js
import React from 'react';

const SecondPage = () => {
  return (
    <div>
      <h1>Hey there</h1>
    </div>
  );
};

export default SecondPage;
